<template>
    <div>
        <div class="name">
          <!-- <div class="icon"><img src="@/assets/img/icon-message@2x.png" alt="" /></div> -->
          <input type="number" name="code" :placeholder="phText" @input="updateValue($event.target.value)" />
          <span @click="getCode" v-if="show">获取验证码</span>
          <div class="number" v-else>{{ count }} 秒后获取</div>
        </div>
        <p class="reg" :style="!realname?'height:14px':''">{{realname?'*请输入正确的验证码':''}}</p>
    </div>
</template>

<script>
import { sendCode } from '@/api/_api/register'
export default {
    props:{ 
        objName:{
            type:String,
            default:()=>''
        },
        phText:{
            type:String,
            default:()=>'请输入验证码'
        }
     },
    data(){
        return{
            realname:false,
            userName:'',
            count:'',
            show:true,
            timer:null,
        }
    },
    methods:{
        updateValue(val){
            this.$emit('input', val.trim())
        },
        getCode(){
            this.$emit('onCodeClick');
        },
        init(obj,type){
            this.$toast.loading({
                message:'加载中',
                forbidClick:true
            })
            let params  = {
                type:type,
                areaCode:'86',
                name:obj.name,
                phoneNum:obj.phone
            }
            //有这个手机号进行进一步操作
            sendCode(params).then((res) => {
                this.$toast.clear();
                if(res.code == 200){
                    const TIME_COUNT = 60;
                    if (!this.timer) {
                        this.count = TIME_COUNT;
                        this.show = false;
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--;
                            } else {
                                this.show = true;
                                clearInterval(this.timer);
                                this.timer = null;
                            }
                        }, 1000);
                    }
                }
            })
        }
    }
}
</script>

<style>

</style>